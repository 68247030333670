@font-face {
    font-family: "Montserrat";
    src: url("../src/assets/fonts/Montserrat/Montserrat.ttf");
  }

@tailwind base;
@tailwind components;
@tailwind utilities;




html,body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: Montserrat;

}
