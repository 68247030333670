/* #root {
  display: flex;
  align-items: center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

.Sform {
  width: 100%;
  min-width: 500px;
  align-self: center;

  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.Sbutton {
  background: #006fff;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.Sbutton:hover {
  filter: contrast(115%);
}

.Sbutton:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

#email {
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .Sform {
    width: 100%;
    min-width: initial;
  }
}

.active {
  color: #eeeeee;
}

/* .video{
  width: 200px !important;
  height: 200px !important;
} */

.custom-select {
  outline: none !important;
}

.post-image {
  height: 35rem;
}

.custom-list {
  display: flex !important;
}

iframe {
  width: 100% !important;
  height: 45rem;
}

.custom-height {
  height: 450px; /* Define la altura que desees */
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: block;
  position: absolute;
  width: 1.7rem;
  height: 1rem;
  left: 1.5rem;
  top: 42.5%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #eeeeee;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #006fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
}

/* General sidebar styles */
.bm-menu {
  background: #006fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #eeeeee;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* LOGIN - CSS */

.section {
  width: 100%;
  position: relative;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.section.active {
  background: #1e1e1e;
}

.section .container {
  position: relative;
  width: 75%;
  height: 80%;
  background: white;
  box-shadow: 0 1.5rem 5rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  transition: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-danger {
  color: red;
  font-size: 15px;
}

.section .container .user .imgBx img {
  width: 40rem;
}

.section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  transition: 0.5s;
}

.section .container .user .formBx h2 {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
  color: #555;
}

.section .container .user .formBx input {
  width: 100%;
  padding: 1rem;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 1.1rem;
  margin: 0.8rem 0;
  letter-spacing: 0.1rem;
  font-weight: 300;
}

.section .container .user .formBx input[type="submit"] {
  max-width: 10rem;
  background: #006fff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  transition: 0.5s;
}
.favorite-heart {
  color: red;
}

.unfavorite-heart {
  color: grey;
}

.section .container .user.signupBx .formBx input[type="submit"] {
  background: #111111;
}

.container:empty {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .bm-burger-button {
    display: block;
  }
}
